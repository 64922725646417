html {
    overflow: hidden;
    background-color: #304e6e;
}

.App {
    text-align: center;
    max-height: 100vh;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.icons {
    width: 25px;
    border: 3px solid white;
    border-radius: 50px;
    background: #e0e0e0;
}

.plane {
    width: 25px;
}

.header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
}

.logo img {
    width: 150px;
}

.footer img {
    width: 50px;
}

.footer .icons {
    padding: 2px;
    margin: 2px;
}

.logo {
    z-index: 10;
    display: flex;
    align-items: center;
    align-content: center;
    flex-direction: row;
    flex-wrap: nowrap;
    margin: 20px;
    justify-content: space-between;
    position: sticky;
}

.minilogo img {
    width: 50px;
}

.dark {
    filter: none;
}

.light {
    filter: invert(100%);
}


.logo-loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
}

.logo-loading img {
    max-width: 300px;
    margin: 10px;
    /*filter: invert(100%);*/
}

.footer {
    width: 100%;
    position: absolute;
    z-index: 10;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    flex-wrap: nowrap;
    bottom: 0;
}

.footer-bar {
    width: 100vw;
    color: black;
}

.footer-bar a {
    margin: 10px;
    text-decoration: none;
    font-size: 12px;
    color: black;
}

.top-bar {
    height: 3vh;
    width: 100%;
    background-color: #000000;
    color: white;
    margin: 0;
    font-size: 14px;
    padding-top: 3px;
}

.twitter-news {
    max-width: 300px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    background-color: #000000cf;
    box-shadow: 5px 5px 17px -5px #000000;
    height: 85vh;
    margin-top: 40px;
    margin-right: 80px;
}

.twitterLogo {
    position: absolute;
    top: 0;
    z-index: 99;
    right: 0;
    margin-top: 4vh;
    margin-right: 1vw;
    width: 60px;
    height: 60px;
}

.twitterLogo img {
    width: 60px;
}

@media screen and (min-width: 600px) {
    .storeButton {
        width: 200px !important;
        position: fixed;
        bottom: 18px;
        left: 18px;
    }
}

@media screen and (max-width: 600px) {
    .storeButton {
        width: 100px !important;
        position: fixed;
        bottom: 30px;
        left: 0px;
    }
}

.gm-control-active,
.gmnoprint {
    display: none;
}

.footer-tab {
    margin-right: 20px;
}

.counter {
    font-size: x-large;
    color: black;
    left: 0;
    bottom: 0;
    position: absolute;
    padding: 25px;
}

.mapboxgl-control-container {
    display: none;
}


.add-to-home-screen {
    position: absolute;
    left: 0;
    background: white;
    height: auto;
    bottom: 20px;
    z-index: 99;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 430px;
    border-radius: 20px;
    padding: 20px;
}

@media screen and (max-width: 600px) {
    .add-to-home-screen {
        width: 70vw;
        bottom: 100px;
    }
}

.add-to-home-screen button {
    background-color: black;
    border: 0;
    color: white;
    padding: 10px;
    width: 100px;
    margin-right: 10px;
    margin-left: 10px;
    border-radius: 20px;
}

.link-button {
    width: 70vw;
    height: 30px;
    background-color: white;
    border: 0px;
    color: black;
    border-radius: 30px;
}
.users-true .users {
    display: block;
}
.users-false .users {
    display: none;
}

.loading {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    animation: fadeOut;
    background-color: white;
    opacity: 1;
    z-index: 11;
}
