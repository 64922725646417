.box {
    padding: 20px;
    border-radius: 20px;
}

.captcha li{
    border: 5px solid white;
    height: 25px;
    width: 25px;
    background-color: #000;
    border-radius: 50%;
    display: inline-block;
    color: white;
    padding: 10px;
    margin: 5px;
    font-size: 22px;
    font-weight: bolder;
}

.captcha {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
}

.calc {
    background-color: #000;
    border-radius: 20px;
    display: inline-block;
    color: white;
    padding: 10px;
    margin: 5px;
    font-size: 22px;
    font-weight: bolder;
    border: 5px solid white;
}