.marker {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 15px;
    height: 15px;
    background-color: #3091f5;
    border: 2px solid #fff;
    border-radius: 100%;
    user-select: none;
    transform: translate(-50%, -50%);
  }

.users {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 5px;
    height: 5px;
    background-color: #ff0000;
    border: 2px solid #fff;
    border-radius: 100%;
    -webkit-user-select: none;
    user-select: none;
    transform: translate(-50%, -50%);
}